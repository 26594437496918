import React from "react"
import { Hero } from "../components/organisms/Hero"
import { Layout } from "../components/organisms/Layout"
import { ProductSpotlight } from "../components/organisms/ProductSpotlight"

const testScreen = () => {
  return (
    <Layout>
      <Hero heroType="halfPage" heading="Test" />
      <ProductSpotlight />
    </Layout>
  )
}

export default testScreen
